
import { defineComponent, ref, onMounted } from "vue";
import Datatable from "@/components/kt-datatable/KTDatatable.vue";
import AddEntregadorModal from "@/components/modals/forms/AddEntregadorModal.vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import axios from "axios";
import Swal from "sweetalert2";



export default defineComponent({
  name: "products-listing",
   data() {
    return {
      entregadores: [],
      entregador: {}
    }
  },
  mounted() {
	   this.$nextTick(function () {
		 this.getEntregadores();
	
	})

  },

  methods:{

  novo(){

    this.entregador = {}

  },


  editar(index){

    this.entregador = this.entregadores[index]


  },  

  excluir(id){

    Swal.fire({
      title: 'Excluir?',
      text: "Tem certeza que deseja excluir este registro!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Sim, Excluir!'
    }).then((result) => {
      if (result.isConfirmed) {


        axios.delete(process.env.VUE_APP_API_URL + '/entregadores/'+id)
       .then(({ data }) => {   
          Swal.fire(
            'Deletado!',
            'Seu registro foi deletado.',
            'success'
          ).then(()=>{
            this.getEntregadores();
          })



        })
        
        .catch(({ response }) => {
          //this.context.commit(Mutations.SET_ERROR, response.data.errors);
          console.log(response)
        });


      }

       
      
    })

  },
    
	getEntregadores(){

		axios.get(process.env.VUE_APP_API_URL + '/entregadores')
       .then(({ data }) => {   
        this.entregadores = data
      })
      .catch(({ response }) => {
        //this.context.commit(Mutations.SET_ERROR, response.data.errors);
        console.log(response)
      });


    }
  },
  components: {
    Datatable,
    AddEntregadorModal,
  },
  computed: {
    // mix the getters into computed with object spread operator
    
  },
  setup() {
   
    const tableHeader = ref([
     
      {
        name: "Nome",
        key: "nome",
        sortable: true,
      }
      
    ]);

    onMounted( async () => {
      setCurrentPageBreadcrumbs("Lista de entregadors", ["entregadors"]);
      
     
    });  

   
    return {
       tableHeader,
      
      
    };
  },
});
