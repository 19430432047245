
import { defineComponent, ref } from "vue";


import axios from "axios";
import Swal from "sweetalert2";

export default defineComponent({
  name: "add-entregador-modal",
  props: ['entregador'],
  data() {
    return {
     
    }
  },
  mounted() {
	   this.$nextTick(function () {
			
	})

  },

  methods:{

    
	salvarEntregador(){

    if(this.entregador.id){
      axios.put(process.env.VUE_APP_API_URL + '/entregadores/'+this.entregador.id, this.entregador)
       .then(({ data }) => {   
         this.$emit('atualizarEntregador')
      }).then(()=>{
          Swal.fire(
            'Atualização!',
            'Registro foi Atualizado.',
            'success'
          )
      })
      .catch(({ response }) => {
        //this.context.commit(Mutations.SET_ERROR, response.data.errors);
        console.log(response)
      });
    }else{
      axios.post(process.env.VUE_APP_API_URL + '/entregadores', this.entregador)
       .then(({ data }) => {   
         this.$emit('atualizarEntregador')
      })
      .catch(({ response }) => {
        //this.context.commit(Mutations.SET_ERROR, response.data.errors);
        console.log(response)
      });
    }
		


    }
  },


  components: {
   
  },




  setup() {
   
  
    return {
      
    
    };
  },
});
